const colors = [
    '#2E2E2E', '#4B4B4B', '#5A5A5A', '#333333', '#3D3D3D', '#424242',
    '#1F1F1F', '#292929', '#2C2C2C', '#2A2A2A', '#373737', '#404040',
    '#181818', '#252525', '#3C3C3C', '#464646', '#4D4D4D', '#525252',
    '#1A1A1A', '#202020', '#232323', '#282828', '#303030', '#383838',
    '#121212', '#161616', '#1C1C1C', '#262626', '#2D2D2D', '#363636',
    '#3B0000', '#660000', '#990000', '#CC0000', '#FF0000', // Dark reds
    '#00003B', '#000066', '#000099', '#0000CC', '#0000FF', // Dark blues
    '#003B00', '#006600', '#009900', '#00CC00', '#00FF00', // Dark greens
    '#3B003B', '#660066', '#990099', '#CC00CC', '#FF00FF', // Dark purples
    '#3B3B00', '#666600', '#999900', '#CCCC00', '#FFFF00', // Dark yellows
    '#003B3B', '#006666', '#009999', '#00CCCC', '#00FFFF', // Dark cyans
];

export default colors;
